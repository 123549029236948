<template>
  <div class="DarkWhite">
    <v-card
      :loading="filterLoading"
      class="mb-5"
    >
    <template slot="progress">
        <v-progress-linear color="header" indeterminate></v-progress-linear>
    </template>
        <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
            v-model="FundID"
            item-text="FundCode"
            item-value="FundID"
            :items="funds"
            hide-details
            color="header"
            label="Filter by Funds"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
            v-model="ClassificationID"
            item-text="ClassificationDescription"
            item-value="ClassificationID"
            :items="classifications"
            hide-details
            color="header"
            label="Filter by Classification"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              dense
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="DateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  dense
                  v-model="DateRange"
                  label="Date Range"
                  color="header"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-5"
                  hide-details
                  clearable
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="DateRange"
                no-title
                scrollable
                range
                color="header"
              >
                <v-spacer></v-spacer>
                <v-btn text color="header" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="header"
                  @click="$refs.menu1.save(DateRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search Item here..."
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
          </v-col>
        </v-row>
        </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="itemList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:[`item.Num`]="{ index }">
        {{ (options.itemsPerPage * (options.page-1)) + index + 1}}
      </template>
      <template v-slot:[`item.ItemName`]="{ item }">
        {{item.ItemName}}
      </template>
      <!-- <template v-slot:[`item.ItemDescription`]="{ item }">
        {{item.ItemDescription}}
      </template> -->
      <template v-slot:[`item.Qty`]="{ item }">
        {{formatQty(item.Qty)}}
      </template>
      <template v-slot:[`item.UnitCost`]="{ item }">
        <span class="text-no-wrap">₱ {{formatPrice(item.UnitCost)}}</span>
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{formatPrice(item.Amount)}}</span>
      </template>
      <template v-slot:[`item.Code`]="{ item }">
        <span class="text-no-wrap">{{item.Code}}</span>
      </template>
      <template v-slot:[`item.DateAcquired`]="{ item }">
        {{ formatDate(item.DateAcquired) }}
      </template>
      <template v-slot:[`item.TransferredFrom`]="{ item }">
        {{getUserData(item.reference.UserID)}}
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="blue darken-2"
          class="mr-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="view(item)"
        >
          View
        </v-btn>
        </span>
      </template>
    </v-data-table>
    <ICSModal :icsData="icsData" />
    <PARModal :parData="parData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    ICSModal: () => import("./ViewICS.vue"),
    PARModal: () => import("./ViewPAR.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    menu1: null,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    itemList: [],
    headers: [
      {
          text: 'Stock/ Property No.',
          align: 'start ',
          sortable: false,
          value: 'StockPropertyNo',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Item Name',
          align: 'start',
          sortable: false,
          value: 'ItemName',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Item Description',
          align: 'start',
          sortable: false,
          value: 'ItemDescription',
          width: 350,
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Qty',
          align: 'center',
          sortable: false,
          value: 'Qty',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Unit Cost',
          align: 'end',
          sortable: false,
          value: 'UnitCost',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        //  {
        //   text: 'Amount',
        //   align: 'end',
        //   sortable: false,
        //   value: 'Amount',
        //   class: "green darken-2 white--text text-caption font-weight-bold"
        // },
        // {
        //   text: 'ICS/PAR No.',
        //   align: 'center',
        //   sortable: false,
        //   value: 'Code',
        //   class: "green darken-2 white--text text-caption font-weight-bold"
        // },
        {
          text: 'Transferred From',
          align: 'center',
          sortable: false,
          value: 'TransferredFrom',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Transferred',
          align: 'center',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      funds: [],
      filterLoading: false,
      FundID: 0,
      ClassificationID: 0,
      DateRange: null,
      classifications: [],
      users: [],
      UserID: 0,
      icsData: [],
      parData: [],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    FundID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    ClassificationID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    UserID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    DateRange: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {
    this.getBudgetTypeAndOffice();
  },
  mounted() {
    this.getClassification();
    this.eventHub.$on("closeViewICS", () => {
      this.initialize();
    });
    this.eventHub.$on("closeViewPAR", () => {
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewICS");
    this.eventHub.$off("closeViewPAR");
  },
  methods: {
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("FundID", this.FundID);
      data.append("ClassificationID", this.ClassificationID);
      data.append("DateRange", this.DateRange ? this.DateRange : '');
      this.axiosCall("/getTransferredTo", "POST", data).then((res) => {
        this.loading = false;
        this.itemList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    view(item){
      if(item.ICSPARType == 1){
        this.icsData = item.details
      }
      else if(item.ICSPARType == 2){
        this.parData = item.details
      }
    },
    getClassification() {
      this.axiosCall("/getClassification", "GET").then((res) => {
        this.classifications = this.prepend({ClassificationID: 0, ClassificationDescription: 'All'},res.data.data.classification);
      });
    },
    getBudgetTypeAndOffice() {
      this.axiosCall(
              '/getOfficeAndOthersForCreateUpdate',
              'GET'
          )
          .then((res) => {
              // this.office = res.data.data.office;
              this.funds = this.prepend({FundID: 0, FundCode: 'All'},res.data.data.fund);
              // this.specificfundsourceList = res.data.data.specificfundsource;
              // this.purposeList = res.data.data.purpose;
              // this.allotmentList = res.data.data.allotmenttype;
              // this.deliveryunit = res.data.data.deliveryunit;
              this.users = this.prepend({UserID: 0, name: 'All'},res.data.data.users);
          })
		},
    prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
    },
    printSC(item) {
      let link = this.apiUrl + "/sc/report/" + item.IARItemID;
      window.open(link);
    },
    printPC(item) {
      let link = this.apiUrl + "/pc/report/" + item.IARItemID;
      window.open(link);
    },
    // printSEPC(item) {
    //   let link = this.apiUrl + "/sepc/report/" + item.IARItemID;
    //   window.open(link);
    // },
    getUserData(id){
      let result = this.users.find((user) => user.UserID == id)
      let name = '';
      if(result){
        name = result.name
      }
      return name;
    }
  },
};
</script>